import styled from 'styled-components'
import React, {useState} from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ContainerWrapper from "../components/ContainerWrapper";
import {Col, Row, Container} from 'react-grid-system';
import clsx from "clsx";
import {graphql} from "gatsby";
import Seo from "../components/SEO";

const StyledDiv = styled.div`

  .charbutton, .charbutton:hover, .charbutton:focus {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 4rem;
    border:none;
    color: #ffffff;
    background-color: #672779;
    font-weight: 300;

    &.disabled {
      color: #bcbcbc;
      cursor: default;
      border-color: #bcbcbc;

      &:hover {
        background-color: unset;
        color: #bcbcbc;
      }
    }

    &:hover {
      background-color: rgb(216,46,180);
      color: white;
    }

    &:focus {
      color: white;
      background-image: linear-gradient(45deg, #DD342B, #D82EB4);
    }
  }

  .charbutton + .charbutton {
    margin-left: 8px;
  }

  input {
    height: 36px;
    width: 100%;
    margin-right: 10rem;
    padding: 14px;
    border-radius: 25px;
    background-color: white;
    border: 1px solid #006CAE;
    color: #006CAE;

    &:placeholder-shown {
      font-style: italic;
    }

    &:focus {
      outline: none;
      //border-width: 2px;
    }
  }

  .commonwidth {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: inline-block;
      align-items: start;
    }
  }

  .reset {
    cursor: pointer;
    margin-left: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 15px;
    border:none;
    position: relative;
    background-image: linear-gradient(45deg, #672779, #006CAE);
    color: white;
    font-weight: 300;
    height: 30px;

    &:hover {
      background-image: linear-gradient(45deg, #DD342B, #D82EB4);
      color: white;
    }
  }

  .term {
    padding-top: 5px;
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 20px;
  }

  ul.terms {
    margin: 0;
    align-items: center;
    list-style: none;

    > li {
      margin-bottom: 30px;

      li, li > p {
        margin: 0;
      }
    }
  }

  .resultList {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 1.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
`

const UNUSED_CHARS = [ ]

const Glossary = ({ pageContext, data }) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext


  const edges = [ ...data.allMdx.edges ]

  // Sort by publication date descending
  edges.sort((left, right) => {
    const leftTitle = left.node.frontmatter.title.toLowerCase()
    const rightTitle = right.node.frontmatter.title.toLowerCase()

    if (leftTitle < rightTitle) {
      return -1;
    }
    if (leftTitle > rightTitle) {
      return 1;
    }
    return 0;
  })

    const [searchKey, setSearchKey] = useState("");
    const [foundDescData, setFoundDescData] = useState(edges);

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = edges.filter((item) => {
                return item.node.frontmatter.title.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFoundDescData(results);
        } else {
            setFoundDescData(edges);
        }

        setSearchKey(keyword);
    };


    const startWithFilter = (e) => {
        const keychar = e.target.id;

        if (keychar !== '') {
            const AlphabetResults = edges.filter((item) => {
                return item.node.frontmatter.title.toLowerCase().startsWith(keychar.toLowerCase());
            });
            setFoundDescData(AlphabetResults.sort());
        } else {
            setFoundDescData(edges);
            setSearchKey('')
        }
    };

    const prepareAlphabets = () => {
        let result = [];
        for (let i = 65; i < 91; i++) {
            result.push(
                <button
                    type="button"
                    className={clsx('charbutton', { disabled: UNUSED_CHARS.includes(String.fromCharCode(i))})}
                    disabled={UNUSED_CHARS.includes(String.fromCharCode(i))}
                    key={i}
                    onClick={startWithFilter} id={String.fromCharCode(i)}
                >{String.fromCharCode(i)}</button>
            )
        }
        return result;
    }

    return (
        <ContainerWrapper crumbs={crumbs} withFeedback>
          <Seo
            title={"Glossary"}
            description={"Find definitions of CoreMedia specific terms and concepts."}
          />
            <Container style={{ padding: 0 }}>
                <Row>

                    <Col xl={12}>
                        <StyledDiv>
                            <div>
                                <input type="input"
                                       value={searchKey}
                                       onChange={filter}
                                       placeholder="Filter"
                                />
                            </div>

                            <br></br>


                            <div className={'commonwidth'}>
                                <div>
                                    {prepareAlphabets()}
                                </div>
                                <div>
                                    <button type="button" key="Reset" onClick={startWithFilter} className={'reset'}>Reset
                                    </button>
                                </div>
                            </div>

                            <div className={'resultList'}>
                                <ul className={'terms'}>

                                    {foundDescData.map((x, index) => (
                                            <li key={index}>
                                                <div className={'term'}>{x.node.frontmatter.title}  </div>
                                                <MDXRenderer>{x.node.body}</MDXRenderer>
                                            </li>
                                        )
                                    )}

                                </ul>
                            </div>
                        </StyledDiv>
                    </Col>

                </Row>
            </Container>
        </ContainerWrapper>

    )
}

export default Glossary

export const query = graphql`
    {
        allMdx(
            filter: {fileAbsolutePath: {regex: "/.*\/glossary\/.*/"}}
            sort: {fields: frontmatter___modified, order: DESC}
        ) {
            edges {
                node {
                    body
                    frontmatter {
                        title
                        modified
                        component
                        version
                    }
                }
            }
        }
    }
`
